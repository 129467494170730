import { NOTIFICATIONS_API_URL, VAPID_KEY } from "./config";

import React, { useEffect, useMemo, useState } from "react";
import { getMessaging, getToken } from "firebase/messaging";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { app } from "./api/firebase";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import HomePage from "./pages/HomePage";
import PostPage from "./pages/PostPage";
import PostPagePreview from "./pages/PostPagePreview";
import PostsPage from "./pages/PostsPage";
import VideoIndexPage from "./pages/VideoIndexPage";
import VideosPage from "./pages/VideosPage";
import VideoPage from "./pages/VideoPage";
import EbookIndexPage from "./pages/EbookIndexPage";
import EbookPage from "./pages/EbookPage";
import PodcastPageIndex from "./pages/PodcastIndexPage";
import PodcastsPage from "./pages/PodcastsPage";
import PodcastPage from "./pages/PodcastPage";
import LoginPage from "./pages/LoginPage";
import SearchPage from "./pages/SearchPage";
import Loading from "./components/Loading";
import Arrow from "./components/Arrow";
import JobPage from "./pages/JobPage";
import JobsPage from "./pages/JobsPage";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import ArticoliPiuLetti from "./pages/ArticoliPiuLetti";
import UltimiArticoli from "./pages/UltimiArticoli";
import ProfilePage from "./pages/ProfilePage";
import Strumenti from "./pages/StrumentiPage";
import GuidaTfr from "./pages/GuidaTfrPage";
import TfrModule from "./pages/TfrModulePage";
import Congratulazioni from "./pages/CongratulazioniPage";
import Grazie from "./pages/GraziePage";
import AssegnoUnicoPage from "./pages/AssegnoUnicoPage";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
import { useDispatch, useSelector } from "react-redux";
import NotificationsPage from "./pages/NotificationsPage";
import {
  markNotificationAsRead,
  setNotifications,
} from "./store/actions/notificationsActions";
import { refreshAuthToken } from ".";

import { store } from "./store";
import { deleteToken } from "./store/actions/auth"; // Ensure this path is correct

import { GET_NOTIFICATIONS } from "./queries/notifications/queries";
import { useMutation, useQuery } from "@apollo/client";

function Template() {
  const authReducer = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

   function decodeJWT(token) {
      const parts = token.split('.');
      
      if (parts.length !== 3) {
      throw new Error('Invalid JWT token');
      }
   
      const payload = parts[1];
   
      const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')));
   
      return decodedPayload;
   }

  useEffect(() => {
    if (authReducer?.id === 4770) {
      const tokenDecoded = decodeJWT(authReducer.token);

      console.log('iat', tokenDecoded?.iat);

      if( tokenDecoded?.iat && tokenDecoded.iat < 1732963956 ){
        console.log('token destroyed', tokenDecoded);
        dispatch(deleteToken());
      }
    }
  }, [authReducer.id, dispatch]);

  const { data } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      status: "ALL",
    },
  });

  useEffect(() => {
    if (data && data.notifications) {
      dispatch(setNotifications(data.notifications));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ authData: authReducer, event: "appOpen" })
      );
    }
  }, [authReducer]);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          notifications: data?.notifications || [],
          event: "notificationClicked",
        })
      );
    }
  }, [data]);

  return (
    <BrowserRouter>
      <Navbar />
      <Loading />
      <Switch>
        <Route path="/utente/reset-password" exact>
          <ResetPassword />
        </Route>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/tema/*" exact>
          <PostsPage />
        </Route>
        <Route path="/video" exact>
          <VideoIndexPage />
        </Route>
        <Route path="/video/page/*" exact>
          <VideoIndexPage />
        </Route>
        <Route path="/podcast" exact>
          <PodcastPageIndex />
        </Route>
        <Route path="/ebook" exact>
          <EbookIndexPage />
        </Route>
        <Route path="/jobs" exact>
          <JobsPage />
        </Route>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/profile" exact>
          <ProfilePage />
        </Route>
        <Route path="/notifications" exact>
          <NotificationsPage />
        </Route>
        {/* <Route path="/articoli-piu-letti" exact>
          <ArticoliPiuLetti />
        </Route> */}
        <Route path="/ultimi-articoli" exact>
          <UltimiArticoli />
        </Route>
        <Route path="/strumenti" exact>
          <Strumenti />
        </Route>
        <Route path="/strumenti/guida-tfr" exact>
          <GuidaTfr />
        </Route>
        <Route path="/strumenti/guida-tfr/*/*" exact>
          <TfrModule />
        </Route>
        <Route path="/strumenti/guida-tfr/congratulazioni" exact>
          <Congratulazioni />
        </Route>
        <Route path="/strumenti/guida-tfr/grazie" exact>
          <Grazie />
        </Route>
        <Route path="/strumenti/guida-assegno-unico-universale" exact>
          <AssegnoUnicoPage />
        </Route>
        <Route path="/privacy-policy" exact>
          <PrivacyPolicy />
        </Route>
        <Route path="/ebook/*" exact>
          <EbookPage />
        </Route>
        <Route path="/cerca/*" exact>
          <SearchPage />
        </Route>
        <Route path="/video/tema/*" exact>
          <VideosPage />
        </Route>
        <Route path="/video/*" exact>
          <VideoPage />
        </Route>
        <Route path="/podcast/tema/*" exact>
          <PodcastsPage />
        </Route>
        <Route path="/podcast/*" exact>
          <PodcastPage />
        </Route>
        <Route path="/jobs/*" exact>
          <JobPage />
        </Route>
        <Route path="/404" exact>
          <h1>error</h1>
        </Route>
        <Route path="/recupera/email" exact>
          <ResetPasswordEmail />
        </Route>
        <Route path="/post-preview/:postId" exact>
          <PostPagePreview />
        </Route>
        <Route path="/*" exact>
          <PostPage />
        </Route>
      </Switch>
      <Arrow />
      <Footer />
    </BrowserRouter>
  );
}

export default React.memo(Template);
